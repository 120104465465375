@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #2196f3;
  --secondary-color: #e91e63;
}
.info li{
  background-color:#673AB7;
  background-color:#395282;
  color: rgb(237, 237, 237);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:0.5rem;
  border-radius: 0.25rem;
  padding-block: 1.5rem;
  align-items: center;
  text-align: center;
}
.info li .values{
  order: 1;
  font-size: 2rem;
}
.info li p {
  order: 2;
  font-size: 0.875rem;
  font-weight: normal;
  color: #c7c4c4;
  color: #dadada;
}