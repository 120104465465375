.calculator-page {
  text-align: center;
  padding: 20px;
  background-color: #f4f4f8;
}

.calculator-list {
  display: grid;
  grid-template-columns: repeat(
    3,
    minmax(250px, 1fr)
  ); /* Show 3 items per row at maximum */
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px;
}

.calculator-card {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  color: #333; /* Ensure text color is consistent */
}

.calculator-card:not(#no-calc-card) {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.calculator-icon {
  width: 80px;
  height: 80px;
  margin: 20px auto 10px;
  display: block;
}

h1 {
  color: #333333;
  margin-bottom: 30px;
}

.calculator-card h2 {
  color: #1a1a1a;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: normal;
  margin: 8px 16px 0;
}

#no-calc-card h2 {color: #F87171;}

.calculator-card p {
  color: #666666;
  font-size: 1rem;
  margin: 16px 8px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .calculator-list {
    grid-template-columns: repeat(
      2,
      minmax(250px, 1fr)
    ); /* 2 items per row for medium screens */
  }
}

@media (max-width: 768px) {
  .calculator-list {
    grid-template-columns: 1fr; /* 1 item per row for small screens */
  }
}

/* Additional modern styling for the cards */
.calculator-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.calculator-card h2,
.calculator-card p {
  text-align: center;
}

/* Add subtle animations for icons */
.calculator-icon {
  transition: transform 0.3s ease;
}

.calculator-card:hover .calculator-icon {
  transform: scale(1.1);
}
